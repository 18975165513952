<template>
  <div>
    <router-view v-if="isReady" />
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
//import { FETCH_USER_BY_ID } from "./store";

export default {
  name: 'DashboardModule',
  data() {
    return {
      isReady: false,
    };
  },
  created() {
    this.bootstrap();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Dashboard'}]);
  },
  methods: {
    bootstrap() {
      setTimeout(() => {
        this.isReady = true;
      }, 2000);
    },
  },
};
</script>

<style></style>
